var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "v-app",
        { attrs: { id: "inspire" } },
        [
          _c(
            "v-content",
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "fill-height": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { "align-center": "", "justify-center": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm8: "", md4: "" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "elevation-12" },
                            [
                              _c("v-toolbar", {
                                attrs: { dark: "", color: "primary" },
                              }),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-form",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "prepend-icon": "mdi-account",
                                          name: "login",
                                          label: "Login",
                                          type: "text",
                                        },
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          id: "password",
                                          "prepend-icon": "mdi-lock",
                                          name: "password",
                                          label: "Password",
                                          type: "password",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c("v-btn", { attrs: { color: "primary" } }, [
                                    _vm._v("Login"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }